import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import Contacts from "../components/Contacts";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  mainpitch,
  description,
  chi,
  perche,
  contatti
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subtitle} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle" style={{margin: "auto", width: "99%", fontWeight: 350}}>{mainpitch.description}</h3>
                    </div>
                  </div>
                  <div className="columns" id="chi-siamo">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {chi.heading}
                      </h3>
                      <p style={{margin: "auto", width: "98%"}}><b>ESPERTI DELLA GRANDINE</b> è un network nazionale di rimozione bolli con un <b>sistema di riparazione senza verniciatura (PDR)</b>, effettuato esclusivamente con apposite leve metalliche, che non ha soltanto il vantaggio meccanico, in quanto <b>non modifica la vernice originale</b>, ma rispetta anche l'ambiente: è infatti assolutamente <b>ecologico</b>.</p>
                    </div>
                  </div>
                  <Features gridItems={chi.blurbs} />
                  <div className="columns" id="perche-noi">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {perche.heading}
                      </h3>
                      <p style={{margin: "auto", width: "98%"}}><b>ESPERTI DELLA GRANDINE</b> offre ad ogni cliente la <b>garanzia a vita</b> sulle riparazioni effettuate, preservando le caratteristiche originali di ogni veicolo.</p>
                    </div>
                  </div>
                  <Features gridItems={perche.blurbs} />
                  <div className="columns" id="contattaci">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {contatti.heading}
                      </h3>
                      {/*<p style={{margin: "auto", width: "98%"}}>Se hai bisogno di parlare con noi, puoi contattarci <a href="mailto:info@espertidellagrandine.it">via mail</a> o <a href="https://wa.me/393519031002" rel="noopener noreferrer" target="_blank">su Whatsapp.</a></p>*/}
                      <p style={{margin: "auto", width: "98%"}}>{contatti.description}</p>
                    </div>
                  </div>
                  <Contacts gridItems={contatti.blurbs} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  mainpitch: PropTypes.object,
  chi: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  perche: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  contatti: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subtitle={frontmatter.subtitle}
        mainpitch={frontmatter.mainpitch}
        chi={frontmatter.chi}
        perche={frontmatter.perche}
        contatti={frontmatter.contatti}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        mainpitch {
          title
          description
        }
        chi {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
        perche {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
            title
          }
          heading
          description
        }
        contatti {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
            contact
          }
          heading
          description
        }
      }
    }
  }
`;
